
export const ProductTitle = {
  PRECISION_BLADES: 'Precision Craft Blades',
  ROTARY_BLADES: 'Rotary Blades',
  SCRAPER_BLADES: 'Scraper Blades',
  SNAP_OFF_BLADES: 'Snap-off Blades',
  UTILITY_BLADES: 'Utility Blades',
  FOLDING_UTILITY_KNIVES: 'Folding Utility Knives',
  PAPER_TRIMMERS: 'Paper Trimmers',
  PROMOTIONAL_GIFT_KNIVES: 'Promotional Gift Knives',
  ROTARY_CUTTERS: 'Rotary Cutters',
  SAFETY_KNIVES: 'Safety Knives',
  SNAP_OFF_CUTTERS: 'Snap-off Cutters',
  ALUMINIUM_FLASHLIGHTS: 'Aluminium Flashlights',
  UTILITY_KNIVES: 'Utility Knives',
  HEADLAMPS: 'Headlamps',
  PLASTIC_FLASHLIGHTS: 'Plastic Flashlights',
  RECHARGEABLE_USB_FLASHLIGHTS: 'Rechargeable USB Flashlights',
  UTILITY_LIGHTS: 'Utility Lights',
  STAPLERS: 'Staplers',
  AUTOMATIC_STAPLERS: 'Automatic Staplers',
  HOLE_PUNCHERS: 'Hole Punchers',
  PENCIL_SHARPENERS: 'Pencil Sharpeners',
};

export const ProductTitleImg = {
  PRECISION_BLADES: 'precision_craft_blades',
  ROTARY_BLADES: 'rotary_blades',
  SCRAPER_BLADES: 'scraper_blades',
  SNAP_OFF_BLADES: 'snap_off_blades',
  UTILITY_BLADES: 'utility_blades',
  FOLDING_UTILITY_KNIVES: 'folding_utility_knives',
  PAPER_TRIMMERS: 'paper_trimmers',
  PROMOTIONAL_GIFT_KNIVES: 'promotional_gift_knives',
  ROTARY_CUTTERS: 'rotary_cutters',
  SAFETY_KNIVES: 'safety_knives',
  SNAP_OFF_CUTTERS: 'snap_off_cutters',
  ALUMINIUM_FLASHLIGHTS: 'aluminium_flashlights',
  UTILITY_KNIVES: 'utility_knives',
  HEADLAMPS: 'headlamps',
  PLASTIC_FLASHLIGHTS: 'plastic_flashlights',
  RECHARGEABLE_USB_FLASHLIGHTS: 'rechargeable_usb_flashlights',
  UTILITY_LIGHTS: 'utility_lights',
  STAPLERS: 'staplers',
  AUTOMATIC_STAPLERS: 'automatic_staplers',
  HOLE_PUNCHERS: 'hole_punchers',
  PENCIL_SHARPENERS: 'pencil_sharpeners',
};
