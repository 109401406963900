import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationService} from '../service/navigation.service';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit, AfterViewInit {

  header1 = 'CREATIVE PROFIT PTE. LIMITED';
  header1P1 = 'We are an international trading company, specialized in cutting-tools, stationery products and lighting fixtures. All products of Creative Profit comply with international quality standard and are greatly appreciated in a variety of different markets throughout the world.';
  header1P2 = 'As a result of high quality and outstanding customer service, Creative Profit has gained a global sales network reaching USA, Germany, Japan, Mexico, etc. We Look forward to establish fruitful and long-term business relationship with any clients interested in our products.';
  header2 = 'OUR VISION';
  header2P1 = 'We question assumptions. We think deeply. We iterate as a lifestyle.';
  header2P2 = 'What we believe: Leading Technology + Valuable Innovation = Creative Profit';

  constructor(public navService: NavigationService) { }

  ngOnInit() {
    if (!this.navService.activated) {
      this.navService.activateClass();
    }
  }

  ngAfterViewInit() {
    this.navService.activated = false;
  }

  toBottom(): void {
    window.scrollTo(0, document.body.scrollHeight);
    const input = document.getElementById('inform-me');
    input.focus();
  }
}
