import { Injectable } from '@angular/core';
import {  ProductInfo, ProductData } from '../model/product';
import { ALLPRODUCTDATA } from '../model/data/productData';
import { ProductType } from '../model/data/productType';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {

  getFeaturedProducts(): ProductInfo[] {
    let featuredProducts: ProductInfo[] = [];
    ALLPRODUCTDATA.map(productData => {
      const list = productData.list
        .filter( product => product.featured )
        .map( featuredProduct => ({
          ...featuredProduct,
          titleForImg: this.getImgPath(featuredProduct)
        }));
      featuredProducts = [...featuredProducts, ...list];
    });
    return featuredProducts;
  }

  getProductsFor(category: string, title: string): ProductInfo[] {
    switch (category.toUpperCase()) {
      case ProductType.KNIVES.toUpperCase():
      case ProductType.LIGHTS.toUpperCase():
      case ProductType.STAPLERS.toUpperCase():
        return this.getProductsBy(category, title);
      default:
        return this.getAllProducts();
    }
  }

  private getProductsBy(category: string, title: string): ProductInfo[] {
    const productData = ALLPRODUCTDATA
      .find(allProductData => allProductData.type.toUpperCase() === category.toUpperCase());
    return this.convertProductDataToList(productData, title);
  }

  private getAllProducts(): ProductInfo[] {
    let allProducts: ProductInfo[] = [];
    ALLPRODUCTDATA.map(productData => {
      const list = this.convertProductDataToList(productData);
      allProducts = [...allProducts, ...list];
    });
    // console.log('all: ' + allProducts.length);
    // congnsole.log('Knives: ' + ALLPRODUCTDATA[0].list.length);
    // console.log('Lights: ' + ALLPRODUCTDATA[1].list.length);
    // console.log('Staplers: ' + ALLPRODUCTDATA[2].list.length);
    return allProducts;
  }

  private convertProductDataToList(productData: ProductData, title: string = ''): ProductInfo[] {
    let productList: ProductInfo[] = productData.list;
    if (title) {
      productList = productList.filter(product => product.title === title);
    }
    return productList.map(product => ({
        ...product,
        titleForImg: this.getImgPath(product)
    }));
  }


  getImgPath(product: ProductInfo): string {
    const imgPath = `${product.titleForImg}/${product.id}`;
    return product.png ? `${imgPath}.png` : `${imgPath}.jpg`;
  }
}
