import { Injectable } from '@angular/core';
import { ProductType } from '../model/data/productType';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

export interface TitleObj {
  value: string;
  isActive: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  mainTitle: TitleObj[] = [
    { value: 'Home', isActive: true }, // 0
    { value: 'All Products', isActive: false }, // 1
    { value: ProductType.KNIVES, isActive: false }, // 2
    { value: ProductType.LIGHTS, isActive: false }, // 3
    { value: ProductType.STAPLERS, isActive: false }, // 4
    { value: 'About Us', isActive: false }, // 5
  ];

  activated = false;

  constructor(
    private router: Router,
    private location: Location
  ) { }

  activateClass(): void {
    window.scroll(0, 0);
    this.activated = true;
    for (const obj of this.mainTitle) {
      obj.isActive = false;
    }
    let curPath = this.location.path();
    const queryIndex = curPath.lastIndexOf('?');
    curPath = queryIndex !== -1 ? curPath.slice(0, queryIndex) : curPath;
    switch (curPath) {
      case '/home':
        this.mainTitle[0].isActive = true;
        break;
      case '/productlist/all':
        this.mainTitle[1].isActive = true;
        break;
      case '/productlist/knives':
        this.mainTitle[2].isActive = true;
        break;
      case '/productlist/lights':
        this.mainTitle[3].isActive = true;
        break;
      case '/productlist/staplers':
        this.mainTitle[4].isActive = true;
        break;
      case '/aboutus':
        this.mainTitle[5].isActive = true;
        break;
      default:
        this.router.navigateByUrl('/home');
        this.mainTitle[0].isActive = true;
        break;
    }
  }

  goProductList(category, title): void {
    this.router.navigate([`productlist/${category}`], { queryParams: { title }} );
  }
}
