
import { Component, ViewEncapsulation } from '@angular/core';
import { Config } from 'ngx-countdown';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ComingSoonComponent {
  endDate: number =  new Date('August 16, 2019 23:59:59').getTime();
  dateNumDiff: number = this.endDate  - Date.now();
  config: Config = {
    leftTime: Math.floor(this.dateNumDiff / 1000 ),
    repaint: function() {
      const me: any = this;
      let content: string;

      me.hands.forEach((hand: any) => {
        if (hand.lastValue !== hand.value) {
          content = '';
          const cur = me.toDigitals(hand.value + 1, hand.bits).join(''),
            next = me.toDigitals(hand.value, hand.bits).join('');

          hand.node.innerHTML = `
            <span class="count curr top">${cur}</span>
            <span class="count next top">${next}</span>
            <span class="count next bottom">${next}</span>
            <span class="count curr bottom">${cur}</span>
          `;
          hand.node.parentElement.className = 'time';
          setTimeout(() => {
            hand.node.parentElement.className = 'time flip';
          });
        }
      });
    },
  };
}
