import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ProductTitle } from '../model/data/productTitle';
import { NavigationService } from '../service/navigation.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})

export class MainNavigationComponent implements OnInit, AfterViewInit {
  bladesTitle: string[];
  otherKnivesTitle: string[];
  lightsTitle: string[];
  staplersTitle: string[];

  constructor(
    public navService: NavigationService
  ) { }

  ngOnInit() {
    if (!this.navService.activated) {
      this.navService.activateClass();
    }
    this.setUpTitles();
  }

  ngAfterViewInit() {
    this.navService.activated = false;
  }

  setUpTitles(): void {
    this.bladesTitle = [
      ProductTitle.PRECISION_BLADES,
      ProductTitle.ROTARY_BLADES,
      ProductTitle.SCRAPER_BLADES,
      ProductTitle.SNAP_OFF_BLADES,
      ProductTitle.UTILITY_BLADES,
    ];
    this.otherKnivesTitle = [
      ProductTitle.UTILITY_KNIVES,
      ProductTitle.FOLDING_UTILITY_KNIVES,
      ProductTitle.SAFETY_KNIVES,
      ProductTitle.PROMOTIONAL_GIFT_KNIVES,
      ProductTitle.ROTARY_CUTTERS,
      ProductTitle.SNAP_OFF_CUTTERS,
      ProductTitle.PAPER_TRIMMERS,
    ];
    this.lightsTitle = [
      ProductTitle.ALUMINIUM_FLASHLIGHTS,
      ProductTitle.PLASTIC_FLASHLIGHTS,
      ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
      ProductTitle.HEADLAMPS,
      ProductTitle.UTILITY_LIGHTS,
    ];
    this.staplersTitle = [
      ProductTitle.STAPLERS,
      ProductTitle.AUTOMATIC_STAPLERS,
      ProductTitle.PENCIL_SHARPENERS,
      ProductTitle.HOLE_PUNCHERS,
    ];
  }

  goTo(category, title): void {
    this.navService.goProductList(category, title);
  }
}
