import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import {ChevronDown, ChevronRight, Edit, Home, Mail, Menu} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  Mail,
  Home,
  ChevronRight,
  ChevronDown,
  Menu,
  Edit
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
