import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductInfo } from '../model/product';
import {ProductDataService} from '../service/product-data.service';
import {NavigationService} from '../service/navigation.service';

@Component({
  selector: 'app-product-list-page',
  templateUrl: './product-list-page.component.html',
  styleUrls: ['./product-list-page.component.scss']
})
export class ProductListPageComponent implements OnInit, AfterViewInit {

  p: number;
  categoryHeader: string;
  products: ProductInfo[];

  constructor(
    private route: ActivatedRoute,
    private productDataService: ProductDataService,
    public navService: NavigationService
  ) {
    this.route.queryParams.subscribe(query => {
      this.getProducts();
    });
  }

  ngOnInit() {
    if (!this.navService.activated) {
      this.navService.activateClass();
    }
    this.getProducts();
  }

  ngAfterViewInit() {
    this.navService.activated = false;
  }

  getProducts(): void {
    let title = '';
    this.route.queryParams.subscribe(query => {
      title = query.title;
    });
    this.route.params.subscribe(routeParam => {
      this.p = 1;
      const category = routeParam.category;
      this.categoryHeader = title ? `${category.toUpperCase()} - ${title}` : category.toUpperCase();
      this.products = this.productDataService.getProductsFor(category, title);
    });
  }
}
