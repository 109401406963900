import {AfterViewInit, Component, OnInit} from '@angular/core';
import { ProductInfo } from '../model/product';
import { ProductDataService } from '../service/product-data.service';
import { NavigationService } from '../service/navigation.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit {

  featuredProducts: ProductInfo[];

  constructor(
    private productDataService: ProductDataService,
    public navService: NavigationService
  ) { }

  ngOnInit() {
    if (!this.navService.activated) {
      this.navService.activateClass();
    }
    this.getFeaturedProducts();
  }

  ngAfterViewInit() {
    this.navService.activated = false;
  }

  getFeaturedProducts(): void {
    this.featuredProducts = this.productDataService.getFeaturedProducts();
  }
}
