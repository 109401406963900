import { ProductData } from '../product';
import { ProductType } from './productType';
import { ProductTitle, ProductTitleImg } from './productTitle';

export const ALLPRODUCTDATA: ProductData[] = [
  {
    type: ProductType.KNIVES,
    list: [
      /*Precision Craft Blades 4*/
      {
        id: 'CP01T-10',
        category: ProductType.KNIVES,
        title: ProductTitle.PRECISION_BLADES,
        titleForImg: ProductTitleImg.PRECISION_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP03T-10',
        category: ProductType.KNIVES,
        title: ProductTitle.PRECISION_BLADES,
        titleForImg: ProductTitleImg.PRECISION_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP011T-2',
        category: ProductType.KNIVES,
        title: ProductTitle.PRECISION_BLADES,
        titleForImg: ProductTitleImg.PRECISION_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP020T-2',
        category: ProductType.KNIVES,
        title: ProductTitle.PRECISION_BLADES,
        titleForImg: ProductTitleImg.PRECISION_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Rotary Blades 4*/
      {
        id: 'CP18TY',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_BLADES,
        titleForImg: ProductTitleImg.ROTARY_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP25TY',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_BLADES,
        titleForImg: ProductTitleImg.ROTARY_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP28TY',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_BLADES,
        titleForImg: ProductTitleImg.ROTARY_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP45TY',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_BLADES,
        titleForImg: ProductTitleImg.ROTARY_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*scraper_blades 4*/
      {
        id: 'CP22T',
        category: ProductType.KNIVES,
        title: ProductTitle.SCRAPER_BLADES,
        titleForImg: ProductTitleImg.SCRAPER_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP721T-10',
        category: ProductType.KNIVES,
        title: ProductTitle.SCRAPER_BLADES,
        titleForImg: ProductTitleImg.SCRAPER_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP802T-10',
        category: ProductType.KNIVES,
        title: ProductTitle.SCRAPER_BLADES,
        titleForImg: ProductTitleImg.SCRAPER_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP882T-3',
        category: ProductType.KNIVES,
        title: ProductTitle.SCRAPER_BLADES,
        titleForImg: ProductTitleImg.SCRAPER_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*snap_off_blades 5*/
      {
        id: 'CP9T',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_BLADES,
        titleForImg: ProductTitleImg.SNAP_OFF_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP18T',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_BLADES,
        titleForImg: ProductTitleImg.SNAP_OFF_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP25T',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_BLADES,
        titleForImg: ProductTitleImg.SNAP_OFF_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP38T-10',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_BLADES,
        titleForImg: ProductTitleImg.SNAP_OFF_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP40T-10',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_BLADES,
        titleForImg: ProductTitleImg.SNAP_OFF_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*utility_blades 3*/
      {
        id: 'CP11T',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_BLADES,
        titleForImg: ProductTitleImg.UTILITY_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP24T-10',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_BLADES,
        titleForImg: ProductTitleImg.UTILITY_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP66T',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_BLADES,
        titleForImg: ProductTitleImg.UTILITY_BLADES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Folding Utility Knives 17*/
      {
        id: 'CP301',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP303',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP305',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP323',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP332',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP350',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP351',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP353',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'CP357',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP670',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP670M',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP670S',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP675',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: true,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'CP676',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP677',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP681',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP952',
        category: ProductType.KNIVES,
        title: ProductTitle.FOLDING_UTILITY_KNIVES,
        titleForImg: ProductTitleImg.FOLDING_UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Paper Trimmers 3*/
      {
        id: 'CP901D',
        category: ProductType.KNIVES,
        title: ProductTitle.PAPER_TRIMMERS,
        titleForImg: ProductTitleImg.PAPER_TRIMMERS,
        price: '$1.00',
        featured: true,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'CP902N',
        category: ProductType.KNIVES,
        title: ProductTitle.PAPER_TRIMMERS,
        titleForImg: ProductTitleImg.PAPER_TRIMMERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP906',
        category: ProductType.KNIVES,
        title: ProductTitle.PAPER_TRIMMERS,
        titleForImg: ProductTitleImg.PAPER_TRIMMERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Promotional Gift Knives 16*/
      {
        id: '708',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP001',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP18-7',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP18-8',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP18-9',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP18-10',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP555',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP555L',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP556',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP705',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP707',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP710',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP795',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP798',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP5100',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP5100S',
        category: ProductType.KNIVES,
        title: ProductTitle.PROMOTIONAL_GIFT_KNIVES,
        titleForImg: ProductTitleImg.PROMOTIONAL_GIFT_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      // /*Rotary Cutters 5*/
      {
        id: 'CP765-1',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_CUTTERS,
        titleForImg: ProductTitleImg.ROTARY_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP765',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_CUTTERS,
        titleForImg: ProductTitleImg.ROTARY_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP766-1',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_CUTTERS,
        titleForImg: ProductTitleImg.ROTARY_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP766',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_CUTTERS,
        titleForImg: ProductTitleImg.ROTARY_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP773',
        category: ProductType.KNIVES,
        title: ProductTitle.ROTARY_CUTTERS,
        titleForImg: ProductTitleImg.ROTARY_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Safety Knives 16*/
      {
        id: 'CP12-1N',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP12-2',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP12-6',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP12-7',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP12-9',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP12-10',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP12-11',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP57N',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP97-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP97',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP97N',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP782',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP782N-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP782N',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP785',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP792',
        category: ProductType.KNIVES,
        title: ProductTitle.SAFETY_KNIVES,
        titleForImg: ProductTitleImg.SAFETY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Snap-off Cutters 67*/
      {
        id: 'CP4E',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP5',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP6',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP7',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP8E',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP28-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP28',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP38-2',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP38',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP41',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP42',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP44',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP45',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP47',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP48N',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP49',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP58',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP59',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP60',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP61',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP62',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP63',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP70-2N',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP70N',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP86-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP86',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP89',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP92',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP96-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP96N-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP98-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP98-2',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP98N-1',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP300',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP500',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP711',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP711N',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP722',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP723',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP754',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP755',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP756',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP759',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP769',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP815',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP816',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP817',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP818',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP820L',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP820S',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP825L',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP825S',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP866L',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP866S',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP900-2',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP996',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP998',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP999',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: true,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'CP999S',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP1800-2',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP2200',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP2500-3',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP2500L',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP8000L',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP8000M',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP8000S',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP8001S',
        category: ProductType.KNIVES,
        title: ProductTitle.SNAP_OFF_CUTTERS,
        titleForImg: ProductTitleImg.SNAP_OFF_CUTTERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Utility Knives 21*/
      {
        id: 'CP73',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP74',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: true,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'CP76',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP79',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP79N',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP80-1',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP80',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP81-1',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP81',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP81N',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP91-2',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP93-1',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP753',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP803',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP804',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP812',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP850',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP950',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP2011',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP6200',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'CP8600',
        category: ProductType.KNIVES,
        title: ProductTitle.UTILITY_KNIVES,
        titleForImg: ProductTitleImg.UTILITY_KNIVES,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*End Knives*/
    ]
  },
  {
    type: ProductType.LIGHTS,
    list: [
      /*Aluminium Flashlights 26*/
      {
        id: '8000',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8000B',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8005',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8005A',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8006',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8007',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8007A',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8007B',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8008',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8025',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8025B',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8025D',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8026',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8028A',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8028B',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8028C',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8056',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8057',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8057A',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8057B',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8058',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8075',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8105',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8106',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8107',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8108',
        category: ProductType.LIGHTS,
        title: ProductTitle.ALUMINIUM_FLASHLIGHTS,
        titleForImg: ProductTitleImg.ALUMINIUM_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Headlamps 16*/
      {
        id: '5623',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5623A',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5623B',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5623C',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5625',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5625R',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5626',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5628',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5629',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: true,
        up: '$2.00',
        isHot: true,
      },
      {
        id: '5630',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '86070A',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '86080A',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '86106A',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '86201A',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'HS86202A',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'HS86203A',
        category: ProductType.LIGHTS,
        title: ProductTitle.HEADLAMPS,
        titleForImg: ProductTitleImg.HEADLAMPS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Plastic Flashlights 12*/
      {
        id: '5923',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5923L',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5923S',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5940',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5940L',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5940S',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5966',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5966S',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5970L',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5970S',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5988A',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5988H',
        category: ProductType.LIGHTS,
        title: ProductTitle.PLASTIC_FLASHLIGHTS,
        titleForImg: ProductTitleImg.PLASTIC_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*Rechargeable USB Flashlights 22*/
      {
        id: '8101C',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8109A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8118',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8119',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '8120',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89181A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89183A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89184A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89184B',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89302A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89303A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89402A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89403A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89404A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89405A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89406A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89407A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89408A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89409A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89410A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89411A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '89412A',
        category: ProductType.LIGHTS,
        title: ProductTitle.RECHARGEABLE_USB_FLASHLIGHTS,
        titleForImg: ProductTitleImg.RECHARGEABLE_USB_FLASHLIGHTS,
        price: '$1.00',
        featured: true,
        up: '$2.00',
        isHot: true,
      },
      /*Utility Lights 9*/
      {
        id: '5903',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5908',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5910',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5975',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5976',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '5979',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '6001',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '6008',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '6008A',
        category: ProductType.LIGHTS,
        title: ProductTitle.UTILITY_LIGHTS,
        titleForImg: ProductTitleImg.UTILITY_LIGHTS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      /*END LIGHTS*/
    ]
  },
  {
    type: ProductType.STAPLERS,
    list: [
      /*Automatic Staplers 2*/
      {
        id: 'ES1201',
        category: ProductType.STAPLERS,
        title: ProductTitle.AUTOMATIC_STAPLERS,
        titleForImg: ProductTitleImg.AUTOMATIC_STAPLERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'ES1203',
        category: ProductType.STAPLERS,
        title: ProductTitle.AUTOMATIC_STAPLERS,
        titleForImg: ProductTitleImg.AUTOMATIC_STAPLERS,
        price: '$1.00',
        featured: true,
        up: '$2.00',
        isHot: true,
      },
      /*Hole Punchers 5*/
      {
        id: '601',
        category: ProductType.STAPLERS,
        title: ProductTitle.HOLE_PUNCHERS,
        titleForImg: ProductTitleImg.HOLE_PUNCHERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '607',
        category: ProductType.STAPLERS,
        title: ProductTitle.HOLE_PUNCHERS,
        titleForImg: ProductTitleImg.HOLE_PUNCHERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '618',
        category: ProductType.STAPLERS,
        title: ProductTitle.HOLE_PUNCHERS,
        titleForImg: ProductTitleImg.HOLE_PUNCHERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'WP3911',
        category: ProductType.STAPLERS,
        title: ProductTitle.HOLE_PUNCHERS,
        titleForImg: ProductTitleImg.HOLE_PUNCHERS,
        price: '$1.00',
        featured: false,
        png: true,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'WP3921',
        category: ProductType.STAPLERS,
        title: ProductTitle.HOLE_PUNCHERS,
        titleForImg: ProductTitleImg.HOLE_PUNCHERS,
        price: '$1.00',
        featured: false,
        png: true,
        up: '$2.00',
        isHot: false,
      },
      /*Pencil Sharpeners 2*/
      {
        id: 'EPC2001',
        category: ProductType.STAPLERS,
        title: ProductTitle.PENCIL_SHARPENERS,
        titleForImg: ProductTitleImg.PENCIL_SHARPENERS,
        price: '$1.00',
        featured: true,
        png: false,
        up: '$2.00',
        isHot: true,
      },
      {
        id: 'EPC2002',
        category: ProductType.STAPLERS,
        title: ProductTitle.PENCIL_SHARPENERS,
        titleForImg: ProductTitleImg.PENCIL_SHARPENERS,
        price: '$1.00',
        featured: false,
        png: false,
        up: '$2.00',
        isHot: false,
      },
      /*Staplers 11*/
      {
        id: '102',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '102A',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '105',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '806',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '836A',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: '3702',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'WP3931',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        png: true,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'WS3191',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        png: true,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'WS3292',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        png: true,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'WS3601',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        png: true,
        up: '$2.00',
        isHot: false,
      },
      {
        id: 'WS3602',
        category: ProductType.STAPLERS,
        title: ProductTitle.STAPLERS,
        titleForImg: ProductTitleImg.STAPLERS,
        price: '$1.00',
        featured: false,
        png: true,
        up: '$2.00',
        isHot: false,
      },
      /*END STAPLERS*/
    ]
  }
];
