import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { ProductInfo } from '../model/product';
import {NavigationService} from '../service/navigation.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, AfterViewInit {

  @Input() product: ProductInfo;
  imgSrc: string;
  constructor(
    public navService: NavigationService
  ) { }

  ngOnInit() {
    if (!this.navService.activated) {
      this.navService.activateClass();
    }
    this.imgSrc = `../../assets/img/products/${this.product.category.toLowerCase()}/${this.product.titleForImg}`;
    this.product.title = `${this.product.title} - ${this.product.id}`;
  }

  ngAfterViewInit() {
    this.navService.activated = false;
  }
}
